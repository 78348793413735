import { useEffect } from "react";
import { useState } from "react";
// import BasicDatePicker from "./dateComponent";
import Modal from "@mui/material/Modal";
import BasicMenu from "./columnFilter";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Conformation from "../../Popup/Conformation";
import { formatDate } from "./tableAction";
import StockManagementSoldHistory from "../../Popup/StockManagementSoldHistory";
import DebtManagementSoldHistory from "../../Popup/DebtManagementSoldHistory";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { jsPDF } from "jspdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import DownloadTableData from "../../Popup/DownloadTableData";
import AppConstrains from "../../constrains/AppConstrains";
const temp = () => {
  return <></>;
};

const CustomTableComponent = ({
  title = "",
  data = [],
  columns = [],
  search = true,
  dateFilter = true,
  download = true,
  columnShow = columns.length || 5,
  columnFilter = true,
  addNew = true,
  s_no = true,
  completeHeader = true,
  action = true,
  Module = temp,
  PopUp = temp,
  selecting = false,
  setSeletecedItemOpen = () => {},
  setSelectedItem = () => {},
  setPageNo = () => {},
  pageNo,
  nextFalse,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  const [open, setOpen] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [conformationPopUpOpen, setConformationPopUpOpen] = useState(false);
  const [tabledata, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  // const [dateFrom, setDateFrom] = useState("");
  // const [dateTo, setDateTo] = useState("");
  const [columnsData, setColumnsData] = useState(columns.slice(0, columnShow));
  // const [pageNo, setPageNo] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [conformationComponent, setConformationComponent] = useState(
    <Conformation
      setConformationPopUpOpen={setConformationPopUpOpen}
      title="Are You sure ?"
      // onConfirm={handleDelete}
      // props={id}
    />
  );
  const [modalComponent, setModalComponent] = useState(<Module />);

  const handlePaid = (id) => {
    const filterData = tabledata.filter((item) => item.id !== id);
    tabledata.forEach((data) => {
      if (data.id === id) {
        data.paid = "paid";
      }
    });
  };

  const handleDateFromChange = (e) => {
    const dateObject = new Date(e);
    // console.log(dateObject, "new Date");
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" }); // Get full month name
    const year = dateObject.getFullYear();
    // console.log(day, "day");
    const dateFormated = `${day} ${month} ${year}`;
    setDateFrom(dateObject);

    // setDateFrom(e.target.value);
  };
  const handleDateToChange = (e) => {
    const dateObject = new Date(e);
    // console.log(dateObject, "new Date");
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" }); // Get full month name
    const year = dateObject.getFullYear();
    // console.log(day, "day");
    const dateFormated = `${day} ${month} ${year}`;
    setDateTo(dateObject);

    // setDateTo(e.target.value);
  };

  // const filterDataByDate = (data, fromDate, toDate) => {
  //   // console.log(fromDate, toDate, "dates");
  //   if (!fromDate || !toDate) return data;

  //   return data.filter((item) => {
  //     const itemDate = new Date(item.entry_date || item.start_date);
  //     console.log(item, "item date");

  //     return itemDate >= fromDate && itemDate <= toDate;
  //   });
  // };

  const searchFilterData = (query, d) => {
    return d.filter((item) => {
      return Object.keys(item).some((key) => {
        return String(item[key]).toLowerCase().includes(query.toLowerCase());
      });
    });
  };
  const downloadCSV = () => {
    const csvRows = [];
    const headers = columnsData.map((col) => {
      if (col.label == "Actions") return;
      return col.label;
    });
    csvRows.push(headers.join(","));

    tabledata.forEach((row) => {
      const values = columnsData.map((col) => {
        const escaped = row[col.name];
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    // console.log(blob, "blob");
    const url = window.URL.createObjectURL(blob);
    // console.log(url, "url");
    const a = document.createElement("a");

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    // console.log(a, "a object");
    a.setAttribute("download", "table_data.csv");

    document.body.appendChild(a);

    a.click();
    document.body.removeChild(a);
  };

  const downloadPDF = async () => {
    const doc = new jsPDF("portrait");

    // Set a smaller font size to accommodate more content
    doc.setFontSize(10);

    const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width
    const pageHeight = doc.internal.pageSize.getHeight(); // Get page height
    const paddingX = 10;
    const paddingY = 10;
    let startY = paddingY;

    // Function to calculate the dynamic column width based on text size
    const getTextWidth = (text, padding = 3) => {
      if (
        text?.split(".")?.[1] === "webp" ||
        text?.split(".")?.[1] === "jpg" ||
        text?.split(".")?.[1] === "png"
      ) {
        // Return a fixed width for images
        return 20 + padding;
      } else {
        return doc.getTextWidth(text) + padding;
      }
    };

    // Calculate dynamic column widths based on the widest text in each column
    const columnWidths = columnsData.map((col) => {
      const maxTextWidth = Math.max(
        ...tabledata.map((row) =>
          getTextWidth(row?.[col.name]?.toString() || "")
        ),
        getTextWidth(col.label)
      );
      return maxTextWidth;
    });

    // Ensure that the total column width does not exceed the page width
    const getVisibleColumns = (startX, remainingColumns) => {
      let totalWidth = 0;
      const visibleColumns = [];

      for (let i = 0; i < remainingColumns.length; i++) {
        const colWidth = columnWidths[remainingColumns[i].index];
        if (totalWidth + colWidth > pageWidth - paddingX * 2) {
          break; // If we exceed the page width, stop adding columns
        }
        visibleColumns.push(remainingColumns[i]);
        totalWidth += colWidth;
      }

      return visibleColumns;
    };

    // Function to add the table headers
    const addTableHeaders = (startX, visibleColumns) => {
      visibleColumns.forEach((col, index) => {
        doc.text(col.label, startX, startY);
        startX += columnWidths[col.index]; // Move horizontally by the column width
      });
    };

    // Function to add a row of data
    const addTableRow = (row, startX, visibleColumns) => {
      visibleColumns.forEach((col, index) => {
        if (
          col.name === "signature_img" ||
          col.name === "img_url" ||
          col.name === "item_img_url"
        ) {
          // If the column is an image, load and add the image
          const imgUrl = AppConstrains.baseURL + "/imagePath/" + row[col.name];
          const imgWidth = Math.min(15, columnWidths[col.index]);
          const imgHeight = imgWidth * (15 / 15); // Maintain aspect ratio

          doc.addImage(imgUrl, "JPEG", startX, startY, 15, 15);
          // doc.rect(startX, startY, 15, 15);
        } else {
          // If the column is text, add the text to the PDF
          const width = Math.max(15, columnWidths[col.index]);
          const height = 15;
          const text = row?.[col.name]?.toString() || "";
          doc.text(text, startX, startY + 10);
          // doc.rect(startX, startY, width, height);
        }
        startX += columnWidths[col.index]; // Move horizontally by the column width
      });
      doc.rect(8, startY, pageWidth - 20, 15);
    };

    // Main loop for handling multiple columns and pages
    let remainingColumns = columnsData.map((col, index) => ({
      ...col,
      index,
    })); // Track original indices of columns

    while (remainingColumns.length > 0) {
      const visibleColumns = getVisibleColumns(paddingX, remainingColumns);

      // Add headers for the current set of visible columns
      addTableHeaders(paddingX, visibleColumns);
      startY += 15; // Move to the next line for the rows

      // Loop through each row in the data for the current set of visible columns
      for (let i = 0; i < tabledata.length; i++) {
        const row = tabledata[i];

        // Check if the content exceeds the page height, add a new page if necessary
        if (startY > pageHeight - 30) {
          doc.addPage(); // Add a new page
          startY = paddingY; // Reset row starting point
          addTableHeaders(paddingX, visibleColumns); // Add the headers to the new page
          startY += 15; // Move to the next row
        }

        addTableRow(row, paddingX, visibleColumns); // Add the current row
        startY += 15; // Move to the next row
      }

      // Remove the visible columns from remainingColumns for the next iteration
      remainingColumns = remainingColumns.slice(visibleColumns.length);

      // If there are still columns left to display, add a new page for them
      if (remainingColumns.length > 0) {
        doc.addPage();
        startY = paddingY; // Reset the Y position for the new page
      }
    }

    // Save the generated PDF
    doc.save("datatable.pdf");
  };

  const handleSort = (columnName) => {
    let direction = "asc";
    if (sortConfig.key === columnName && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnName, direction });

    const sortedData = [...tabledata].sort((a, b) => {
      if (a[columnName] < b[columnName]) return direction === "asc" ? -1 : 1;
      if (a[columnName] > b[columnName]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setTableData(sortedData);
  };
  const handleDelete = (id) => {
    // console.log(id, "delteding id");
    const filteredData = tabledata.filter((item) => item.id !== id);
    setTableData(filteredData);
  };

  // useEffect(() => {
  //   //filterByDate
  //   if (dateFilter && dateFrom && dateTo) {
  //     const filteredData = filterDataByDate(data, dateFrom, dateTo);
  //     setTableData(filteredData);
  //     // console.log(filteredData, "filtered by date");
  //   }
  // }, [dateFrom, dateTo]);

  useEffect(() => {
    // filterBySearch
    if (search) {
      // console.log(searchText, "searchText");
      // console.log(filterData(searchText, data), "filterData");

      const filteredData = searchFilterData(searchText, data);
      // console.log(searchFilterData, "filteredData");
      setTableData(filteredData);
      // setTableData(columns);
    }
  }, [searchText]);
  // console.log(module, "module");
  // console.log(tabledata, "tableData");
  // console.log(dateFrom, "dateFrom");
  // console.log(dateTo, "dateTo");

  useEffect(() => {
    if (data) {
      setTableData(data);
      // console.log(data, "data in custom table");
    }
  }, [data]);
  // console.log(tabledata, "tableData");
  return (
    <div className="sm:px-5 px-3 sm:py-14 py-12 bg-[#F4F4F4] min-h-[100dvh]">
      {completeHeader && (
        <>
          <header className="flex flex-col items-start justify-start py-6 text-left sm:justify-between sm:flex-row sm:items-center gap-y-3">
            <div className="text-lg text-[#030303] not-italic font-semibold leading-normal">
              {title}
            </div>
            <div className="flex items-center justify-center gap-2 sm:gap-6">
              {addNew && (
                <Tooltip title="Add New">
                  <IconButton
                    onClick={() => {
                      setIsUpdateMode(false);
                      setModalComponent(
                        <Module
                          rowData=""
                          setOpen={setOpen}
                          setTableData={setTableData}
                          isUpdateMode={false}
                        />
                      );
                      setOpen(true);
                    }}
                  >
                    <div className="bg-[#EBD37D] flex justify-center items-center gap-2 px-2 py-1 rounded">
                      <div className="text-[16px] font-medium">Add New</div>

                      <div>
                        <svg
                          enableBackground="new 0 0 50 50"
                          height="20px"
                          id="Layer_1"
                          version="1.1"
                          viewBox="0 0 50 50"
                          width="20px"
                          xmlSpace="preserve"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <rect fill="none" height={50} width={50} />
                          <line
                            fill="none"
                            stroke="#000000"
                            strokeMiterlimit={10}
                            strokeWidth={4}
                            x1={9}
                            x2={41}
                            y1={25}
                            y2={25}
                          />
                          <line
                            fill="none"
                            stroke="#000000"
                            strokeMiterlimit={10}
                            strokeWidth={4}
                            x1={25}
                            x2={25}
                            y1={9}
                            y2={41}
                          />
                        </svg>
                      </div>
                    </div>
                  </IconButton>
                </Tooltip>
              )}

              {download && (
                <Tooltip title="Download">
                  <IconButton>
                    <button
                      onClick={() => {
                        // downloadCSV();
                        // downloadPDF();
                        setModalComponent(
                          <DownloadTableData
                            downloadPDF={downloadPDF}
                            downloadCSV={downloadCSV}
                          />
                        );
                        setOpen(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M9.87816 18.622C11.0494 19.794 12.9488 19.7946 14.1208 18.6234C14.1213 18.6229 14.1217 18.6225 14.1222 18.622L17.3332 15.411C17.7036 15.0014 17.6717 14.369 17.2621 13.9986C16.8807 13.6537 16.2999 13.6543 15.9192 14L12.9932 16.927L13.0002 1.50003C13.0001 0.947703 12.5524 0.5 12.0001 0.5C11.4479 0.5 11.0002 0.947703 11.0002 1.49998L10.9912 16.908L8.08116 14C7.69041 13.6095 7.05713 13.6098 6.66666 14.0005C6.27619 14.3913 6.27643 15.0245 6.66718 15.415L9.87816 18.622Z"
                          fill="#333333"
                        />
                        <path
                          d="M23 16.5C22.4477 16.5 22 16.9477 22 17.5V21.5C22 22.0523 21.5523 22.5 21 22.5H3C2.44772 22.5 2.00002 22.0523 2.00002 21.5V17.5C2.00002 16.9477 1.55231 16.5 1.00003 16.5C0.447703 16.5 0 16.9477 0 17.5V21.5C0 23.1569 1.34316 24.5 3 24.5H21C22.6568 24.5 24 23.1569 24 21.5V17.5C24 16.9477 23.5523 16.5 23 16.5Z"
                          fill="#333333"
                        />
                      </svg>
                    </button>
                  </IconButton>
                </Tooltip>
              )}
              {columnFilter && (
                <Tooltip title="Filter">
                  <BasicMenu
                    columns={columns}
                    setColumnsData={setColumnsData}
                    columnShow={columnShow}
                  />
                </Tooltip>
              )}
            </div>
          </header>

          <div className="flex flex-row flex-wrap items-center justify-between w-full px-4 py-6 pb-4 bg-white rounded-t-lg sm:px-8 gap-y-4">
            <div className="w-full max-w-[453px] flex">
              {search && (
                <input
                  className="border p-2.5 outline-none w-full"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  type="text"
                />
              )}
            </div>
            <div className="flex flex-col sm:flex-row gap-4 !min-w-0">
              {dateFilter && (
                <>
                  <div className="border px-0.5 sm:px-4 py-2 !min-w-0">
                    <label htmlFor="fromDate1">From:</label>
                    <input
                      className="outline-none !min-w-0"
                      type="date"
                      name="fromDate1"
                      id="fromDate1"
                      onChange={(e) => handleDateFromChange(e.target.value)}
                    />
                  </div>
                  <div className="border px-0.5 sm:px-4 py-2 !min-w-0">
                    <label htmlFor="toDate1">To:</label>
                    <input
                      className="outline-none !min-w-0"
                      type="date"
                      name="toDate1"
                      id="toDate1"
                      onChange={(e) => handleDateToChange(e.target.value)}
                    />
                  </div>
                  {/* 
                  <BasicDatePicker
                    title="From"
                    selectedDate={dateFrom}
                    handleDateChange={handleDateFromChange}
                  />
                  <BasicDatePicker
                    title="To"
                    selectedDate={dateTo}
                    handleDateChange={handleDateToChange}
                  /> */}
                </>
              )}
            </div>
          </div>
        </>
      )}

      <div //main table
        className="relative px-4 py-6 overflow-x-scroll bg-white rounded-b-lg sm:px-8"
      >
        <table className="w-full text-nowrap">
          <thead className="text-left ">
            <tr className="border-b ">
              {/* {s_no && (
                <th className="py-2 pr-24 sticky right-[-28px] sm:right-[-33px] bg-white">
                  <div className="flex gap-4">
                    <div> {"S No."}</div>
                  </div>
                </th>
              )} */}
              {/* <th className="pr-[20px]">S No.</th> */}
              {columnsData?.map((column, index) => {
                if (column.name === "image") {
                  return (
                    <th key={index} className={`py-2 pr-24 cursor-default `}>
                      <div className="flex gap-4">
                        <div> {column.label}</div>
                      </div>
                    </th>
                  );
                }
                return (
                  <th
                    key={index}
                    className={`py-2 pr-24  cursor-pointer `}
                    onClick={() => {
                      column?.option?.sort !== false && handleSort(column.name);
                    }}
                  >
                    {/* {console.log(column.label, "column label")} */}
                    <div className="flex gap-4">
                      <div> {column.label}</div>
                      {column?.option?.sort === false ? (
                        ""
                      ) : (
                        <div
                          className={` duration-100 ${
                            sortConfig.direction === "asc" &&
                            sortConfig.key === column.name
                              ? "rotate-0"
                              : "rotate-180"
                          }`}
                        >
                          <ArrowDropUpIcon />
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
              {action && (
                <th className="py-2 pr-24 sticky right-[-28px] sm:right-[-33px] bg-white">
                  <div className="flex gap-4">
                    <div> {"Actions"}</div>
                  </div>
                </th>
              )}
            </tr>
          </thead>

          <tbody className="">
            {tabledata?.map((row, rowIndex) => (
              <tr
                className={`border-b ${
                  selecting && "hover:bg-[#f4f4f4] cursor-pointer"
                } text-[#030303] text-[16px] font-normal leading-7 text-left`}
                key={rowIndex}
                onClick={() => {
                  selecting && setSelectedItem(row);
                  selecting && setSeletecedItemOpen(false);
                }}
              >
                {/* <td>{rowIndex + 1}</td> */}
                {columnsData.map((column, columnIndex) => {
                  if (column?.option?.tablebodyCustom) {
                    return column?.option?.tablebodyCustom(
                      row,
                      column,
                      setOpen,
                      setModalComponent
                    );
                  }
                  // if (column.name === "Date") {
                  //   return (
                  //     <td className={`py-2 pr-24`} key={columnIndex}>
                  //       {/* {`formatDate${row[column.name]}`} */}
                  //       {formatDate(row[column.name])}
                  //     </td>
                  //   );
                  // }
                  if (column.name === "paid_amount") {
                    return (
                      <td
                        className={`py-2 pr-24 ${
                          row[column.name] ? "text-[#588B58]" : "text-[#DDB900]"
                        }`}
                        key={columnIndex}
                      >
                        <div className="flex items-center gap-4">
                          {row[column.name] ? "paid" : "pending"}
                          {row[column.name] ? (
                            <Tooltip title="sold History">
                              <IconButton
                                onClick={() => {
                                  setModalComponent(
                                    <DebtManagementSoldHistory
                                      props={row["id"]}
                                    />
                                  );
                                  setOpen(true);
                                }}
                              >
                                <img src="/images/info.svg" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="confirm">
                              <IconButton
                                onClick={() => {
                                  setConformationComponent(
                                    <Conformation
                                      setConformationPopUpOpen={
                                        setConformationPopUpOpen
                                      }
                                      title="Are You sure you want to Settle ?"
                                      onConfirm={handlePaid}
                                      props={row["id"]}
                                    />
                                  );
                                  setConformationPopUpOpen(true);
                                }}
                              >
                                <img
                                  src="/images/tick.svg"
                                  // onClick={() => setPopUpOpen(true)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    );
                  }
                  // if (column.name === "giveTake") {
                  //   return (
                  //     <td
                  //       className={`py-2 pr-24 ${
                  //         row[column.name] === "gave"
                  //           ? "text-[#588B58]"
                  //           : "text-[#B15151]"
                  //       } `}
                  //       key={columnIndex}
                  //     >
                  //       {`${row[column.name]}`}
                  //     </td>
                  //   );
                  // }
                  // if (column.name === "dueAmount") {
                  //   return (
                  //     <td className={`py-2 pr-24`} key={columnIndex}>
                  //       {`₹ ${row[column.name]} `}
                  //     </td>
                  //   );
                  // }
                  // if (column.name === "interest") {
                  //   return (
                  //     <td className={`py-2 pr-24`} key={columnIndex}>
                  //       {`₹ ${row[column.name]} `}
                  //     </td>
                  //   );
                  // }

                  // if (column.name === "weight") {
                  //   return (
                  //     <td className={`py-2 pr-24`} key={columnIndex}>
                  //       {`${row[column.name]} g`}
                  //     </td>
                  //   );
                  // }

                  // if (column.name === "soldStatus") {
                  //   return (
                  //     <td className={`py-2 pr-24`} key={columnIndex}>
                  //       <div className="flex items-center justify-start gap-4 capitalize">
                  //         {`${row[column.name] === false ? "sold" : "unsold"}`}
                  //         {row[column.name] === false ? (
                  //           <Tooltip title="sold History">
                  //             <IconButton onClick={() => setPopUpOpen(true)}>
                  //               <img src="/images/info.svg" />
                  //             </IconButton>
                  //           </Tooltip>
                  //         ) : (
                  //           ""
                  //         )}
                  //       </div>
                  //     </td>
                  //   );
                  // }

                  return (
                    <td className={`py-2 pr-24`} key={columnIndex}>
                      {row[column?.name]}
                    </td>
                  );
                })}
                {action && (
                  <td className="py-2 pr-24 sticky right-[-28px] sm:right-[-32px] bg-white">
                    <div className="flex gap-5">
                      {/* <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setConformationComponent(
                              <Conformation
                                setConformationPopUpOpen={
                                  setConformationPopUpOpen
                                }
                                title="Are You sure ?"
                                onConfirm={handleDelete}
                                props={row["id"]}
                              />
                            );
                            setConformationPopUpOpen(true);
                          }}
                        >
                          <div //delete
                          >
                            <img src="/images/delete.svg" />
                          </div>
                        </IconButton>
                      </Tooltip> */}

                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setModalComponent(
                              <Module
                                rowData={row}
                                setOpen={setOpen}
                                isUpdateMode={true}
                              />
                            );
                            // console.log("ediing");
                            setOpen(true);
                          }}
                        >
                          <div //edite
                          >
                            <img src="/images/edit.svg" />
                          </div>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end w-full gap-4 mt-4">
        <div
          className="flex items-center justify-center rounded-full cursor-pointer bg-black/20 size-7"
          onClick={() => {
            if (pageNo === 1) {
              setPageNo(1);
            } else {
              setPageNo((prev) => prev - 1);
            }
          }}
        >
          <NavigateBeforeIcon />
        </div>
        <div className="flex items-center justify-center rounded-full bg-black/20 size-7 ">
          {pageNo}
        </div>

        <div
          className={`bg-black/20 rounded-full size-7 flex justify-center items-center cursor-pointer`}
          onClick={() => {
            setPageNo((prev) => prev + 1);
          }}
        >
          <NavigateNextIcon />
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        {modalComponent}
      </Modal>
      <Modal
        keepMounted
        open={popUpOpen}
        onClose={() => setPopUpOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <PopUp />
      </Modal>
      <Modal
        keepMounted
        open={conformationPopUpOpen}
        onClose={() => setConformationPopUpOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        {conformationComponent}
      </Modal>
    </div>
  );
};
export default CustomTableComponent;

// sdfsfdfsdf
