import React, { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import { submitFine } from "../actions";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import { toast } from "react-toastify";

import TextField from "../CommonComponents/TextField";
import Dropdown from "../CommonComponents/Dropdown";
import Upload from "../CommonComponents/Upload";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Progressbar from "../CommonComponents/Progressbar";
import axios from "axios";
import { AuthContext } from "../context/authcontext";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "70vh",
  maxHeight: "70vh",
  width: "50%",
  bgcolor: "#FFF",
  maxWidth: "800px",
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const DebitManagementModal = ({ rowData, setOpen }) => {
  // const [DebtpendingStatus, setDebtPendingStatus] = useState(false);
  const [tab, setTab] = useState(0);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // console.log(value, "value of tab");
    setValue(newValue);
  };
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  // console.log(rowData?.rowData, "rowData");
  const [debtdata, setDebtData] = useState({});

  const handleValueChnage = (e) => {
    // console.log(e.target.value);
    setDebtData({ ...debtdata, [e.target.name]: e.target.value });
  };
  // console.log(rowData, "rowwwwwww");
  const handleSubmit = async () => {
    let apiname =
      `${process.env.REACT_APP_SERVER_HOST}` + "api/accounts/updatedebtinfo";
    let dataSend = {
      id: rowData?.id,
      debt_amount: debtdata?.amount,
      intrest_percentage: debtdata?.intrest_percentage,
      start_date: debtdata?.start_date,
      due_end_date: debtdata?.due_end_date,
    };
    if (value === 1) {
      apiname =
        `${process.env.REACT_APP_SERVER_HOST}` +
        "api/accounts/adddebtinstallment";
      dataSend = {
        debt_id: rowData?.id,
        paid_amount: parseFloat(debtdata?.paid_amount),
        interest_amt: debtdata?.interest_amt,
      };
    }
    try {
      setLoading(true);
      const response = await axios.post(apiname, dataSend);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        setOpen(false);
        setFetchAgain((prev) => !prev);
      } else {
        toast.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error, "error in update debt");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData) {
      setDebtData({
        account_id: rowData?.account_id,
        active: rowData?.active,
        amount: rowData?.amount,
        due_end_date: rowData?.due_end_date,
        id: rowData?.id,
        interest_amt: rowData?.interest_amt,
        intrest_percentage: rowData?.intrest_percentage,
        paid_amount: rowData?.paid_amount,
        start_date: rowData?.start_date,
        user_name: rowData?.user_name,
      });
    }
  }, [rowData]);

  useEffect(() => {
    let interest_amt = parseFloat(
      (parseFloat(debtdata?.amount) *
        parseFloat(debtdata?.intrest_percentage)) /
        100
    );
    // console.log(
    //   interest_amt,
    //   debtdata?.amount,
    //   debtdata?.interest_amt,
    //   "interest amount fdsafds"
    // );
    setDebtData({ ...debtdata, interest_amt: interest_amt });
  }, [debtdata?.intrest_percentage]);
  // console.log(debtdata, "debtData");
  return (
    <Box sx={style} className="md:w-[50%] sm:!w-[80%] !w-[90%] sm:p-8 !p-4">
      <div className="flex flex-col items-center gap-4 !w-full sm:p-[32px] p-4 capitalize">
        <h1 className="text-[18px] ">Debt Management</h1>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Installment" {...a11yProps(0)} />
              <Tab label="Paid" {...a11yProps(1)} />
              {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="modalFormDivs items-center !justify-center flex-col sm:flex-row mt-2">
              <TextField
                label="Interest Percentage"
                placeholder="8%"
                type="number"
                onChange={handleValueChnage}
                name={"intrest_percentage"}
                value={debtdata?.intrest_percentage}
              />
              <TextField
                label="Amount"
                placeholder="Rs 10000"
                editable={false}
                type="number"
                name={"amount"}
                onChange={handleValueChnage}
                value={debtdata?.amount}
              />
              <div className="modalFormDivs items-center !justify-center flex-col sm:flex-row mt-2">
                <TextField
                  label="Interest Amount"
                  placeholder="Rs 10000"
                  editable={false}
                  type="number"
                  name={"interest_amt"}
                  onChange={handleValueChnage}
                  value={debtdata?.interest_amt}
                />
              </div>

              {/* <Box sx={{ maxWidth: 300 }} className="sm:!w-[300px]">
                <Slider
                  max={rowData?.Amount}
                  disabled
                  defaultValue={rowData?.amountPaid}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  className="!text-[#000]"
                />
                <p className="text-[16px] text-[#999]">
                  {(rowData?.amountPaid / rowData?.Amount) * 100}% of amount is
                  paid
                </p>
              </Box> */}
            </div>
            {/* <div className="mt-2 modalFormDivs"></div> */}
            <div className="flex-col mt-2 modalFormDivs sm:flex-row">
              <TextField
                label="Start Date"
                placeholder="ENter date"
                type="date"
                name={"start_date"}
                value={debtdata?.start_date}
                onChange={handleValueChnage}
              />
              <TextField
                label="Debt Due Date"
                placeholder="ENter date"
                type="date"
                name={"due_end_date"}
                value={debtdata?.due_end_date}
                onChange={handleValueChnage}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="modalFormDivs items-center !justify-center flex-col sm:flex-row mt-2">
              <TextField
                label="Paid Amount"
                placeholder="Rs 10000"
                editable={true}
                type="number"
                name={"paid_amount"}
                onChange={handleValueChnage}
                value={debtdata?.paid_amount}
              />
              <TextField
                label="interest Amount"
                placeholder="Rs 10000"
                // editable={false}
                type="number"
                name={"interest_amt"}
                onChange={handleValueChnage}
                value={debtdata?.interest_amt}
              />
            </div>
          </CustomTabPanel>
        </Box>
        <div className="w-full">
          <div>
            <div className="formLayout">
              {/* <div className="flex flex-wrap items-center justify-between w-full gap-4 md:flex-nowrap">
                <div className="modalFormDivs flex-col-reverse xl:w-[50%] w-full !gap-0">
                  <Progressbar max={1000} defaultValue={300} />
                  <p className="text-[16px] text-[#999]">
                    60% of amount is paid
                  </p>
                </div>
                <h3 className="!w-[232px] h-fit p-2 rounded justify-center flex items-center border border-[#EBD37D] text-center text-[#030303]">
                  Add Installment
                </h3>
              </div> */}
              {/* <div className="modalFormDivs">
                <TextField
                  label="Amount Paid Now"
                  placeholder="eg 1000 Rs"
                  type="number"
                />
              </div> */}
              {/* <div className="flex gap-3">
                <label
                  htmlFor="intrestPayment"
                  className="text-[#030303] text-base not-italic font-normal leading-[28.8px]"
                >
                  ₹XX,000 interest included
                </label>
                <input
                  type="checkbox"
                  name="intrestPayment"
                  id="intrestPayment"
                />
              </div> */}
              {/* <div className="modalFormDivs">
                <Dropdown
                  label="Type"
                  placeholder="eg 50g"
                  optionarr={["credit", "debit"]}
                />
                <TextField
                  label="Fine Deposited"
                  placeholder="eg 50g"
                  type="number"
                />
              </div> */}
              {/* <div className="modalFormDivs">
                <TextField
                  label="Date of Installment"
                  placeholder="Enter Date"
                  type="date"
                />
              </div> */}
              {/* <hr className="h-[2px] bg-[#000] opacity-40 w-full mt-6" /> */}
              {/* <div className="flex items-start justify-start gap-1 mb-4">
                <div>
                  <p className="formsLabel">Debt Payment (Udhaar)</p>
                  <span className="text-[12px] text-[#999]">
                    Automatically added to Debt Management List
                  </span>
                </div>
              </div> */}

              {/* <>
                <div className="modalFormDivs items-center !justify-center flex-col sm:flex-row">
                  <TextField
                    label="Amount Paid"
                    placeholder="Rs 10000"
                    type="number"
                    value={rowData?.amountPaid}
                  />
                  <Box sx={{ maxWidth: 300 }} className="sm:!w-[300px]">
                    <Slider
                      max={rowData?.Amount}
                      disabled
                      defaultValue={rowData?.amountPaid}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      className="!text-[#000]"
                    />
                    <p className="text-[16px] text-[#999]">
                      {(rowData?.amountPaid / rowData?.Amount) * 100}% of amount
                      is paid
                    </p>
                  </Box>
                </div>
                <div className="modalFormDivs">
                  <TextField
                    label="Interest Percentage"
                    placeholder="8%"
                    type="number"
                    value={rowData?.interestPercentage}
                  />
                </div>
                <div className="flex-col modalFormDivs sm:flex-row">
                  <TextField
                    label="Start Date"
                    placeholder="ENter date"
                    type="date"
                    value={rowData?.startDate}
                  />
                  <TextField
                    label="Debt Due Date"
                    placeholder="ENter date"
                    type="date"
                    value={rowData?.debtDueDate}
                  />
                </div>
                <hr className="h-[2px] bg-[#000] opacity-40 w-full mt-6 mb-4" />
              </> */}

              <div>
                <p className="text-[14px] font-medium text-[#273240]">
                  Edit this in account management{" "}
                  <a href="account_management" className="text-[#9C832B]">
                    Go to account management
                  </a>
                </p>
              </div>
              <div className="flex w-full gap-1">
                <button
                  className="discardBtn"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Discard
                </button>
                <button className="submitBtn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DebitManagementModal;
