import React, { useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import AppConstrains from "../constrains/AppConstrains";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",
  maxWidth: 630,
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const AccountManagementSoldItem = ({ id }) => {
  // console.log("Requesting item with ID:", id);
  const [item, setItem] = React.useState({});
  useEffect(() => {
    if (id) {
      const getItem = async () => {
        try {
          const responseItem = await axios.post(
            `${process.env.REACT_APP_SERVER_HOST}` +
              "api/stocks/getitemdetails",
            { id }
            // {withCredentials:true}
          );
          // console.log("items : ", responseItem.data.data);
          setItem(responseItem.data.data);
          // console.log(item, "itemitem");
        } catch (error) {
          console.log(error);
          setItem({});
        }
      };
      getItem();
    }
  }, [id]);

  return (
    <Box sx={style} className="!w-[90%] md:!w-full">
      <div className="flex flex-col justify-center">
        <h1 className="text-[18px] py-4 text-center">Sold item</h1>
        <div className="flex flex-col gap-4 p-3 sm:p-6">
          <div className="flex px-4 sm:px-[40px] justify-between gap-20 h-[196px] bg-[#F4F4F4]">
            <div className="flex items-center justify-center">
              {/* <img className="sm:w-[180px] w-[80px]" src={`${item.img_url}`} /> */}
              <img
                className=" object-cover rounded h-[80%]"
                src={`${AppConstrains.baseURL}/imagePath/${item.img_url}`}
                alt="logo"
              />
            </div>
            <div className="flex justify-start items-center w-[50%] ">
              <div className="flex flex-col gap-4 flex-nowrap text-nowrap">
                <div className="flex flex-wrap justify-between gap-28 gap-y-1 md:flex-nowrap">
                  <div>Weight:</div>
                  <div className="text-wrap">{`${
                    item?.weight ? `${item.weight} g` : "Item not found"
                  }`}</div>
                </div>
                <div className="flex flex-wrap justify-between gap-28 gap-y-1 md:flex-nowrap">
                  <div>Name:</div>
                  <div className="text-wrap">{`${
                    item?.name ? `${item.name}` : "Item not found"
                  }`}</div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="w-full h-[1px] bg-black/10"></div> */}

          {/* <div>
              <CustomTableComponent
                //   title="Stock Management"
                data={data.slice(0, 10)}
                columns={columns}
                search={false}
                dateFilter={false}
                columnFilter={false}
                addNew={false}
                download={false}
                completeHeader={false}
                action={false}
                columnShow={4}
                //   Module={StockManagementModal}
                //   PopUp={StockManagementPopUp}
              />
            </div> */}
        </div>
      </div>
    </Box>
  );
};

export default AccountManagementSoldItem;
